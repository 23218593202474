<template>
    <div
        class="flex flex-col md:flex-row gap-4 items-center mt-4 justify-center"
    >
        <div
            class="-space-x-5 flex items-center relative [&>img]:border-4 [&>img]:border-white dark:[&>img]:border-black"
        >
            <template v-if="displayAvatars">
                <img
                    v-for="avatar in displayAvatars"
                    :key="avatar"
                    :src="avatar"
                    alt="Avatar of one of our email list subscribers"
                    class="rounded-full h-12 w-12"
                />
            </template>
        </div>
        <div class="flex flex-col">
            <div
                class="flex items-center justify-center md:justify-start text-yellow-400 mb-1"
            >
                <font-awesome-icon
                    :icon="['fas', 'fa-star']"
                    v-for="star in 5"
                    :key="star"
                    class="mr-1"
                />
            </div>
            <span class="text-gray-700 dark:text-white text-base">
                {{ subscriberText }}
            </span>
        </div>
    </div>
</template>

<script setup>
import { computed } from "vue";
import { usePage } from "@inertiajs/vue3";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faStar } from "@fortawesome/free-solid-svg-icons";

library.add(faStar);

const props = defineProps({
    avatars: {
        type: Object,
        required: false,
    },
    subscriberText: {
        type: String,
        default: "Trusted by 5000+ job seekers",
    },
});

// Default avatars
const defaultAvatars = [
    "https://randomuser.me/api/portraits/women/1.jpg",
    "https://randomuser.me/api/portraits/men/1.jpg",
    "https://randomuser.me/api/portraits/women/2.jpg",
    "https://randomuser.me/api/portraits/men/2.jpg",
    "https://randomuser.me/api/portraits/women/3.jpg",
];

const displayAvatars = computed(() => {
    if (props.avatars) {
        return props.avatars;
    }

    const page = usePage();
    const pageAvatars = page.props.avatars;

    // if pageAvatars array is empty, return default avatars
    return pageAvatars.length ? pageAvatars : defaultAvatars;
});
</script>
