<template>
    <li class="relative group">
        <div
            class="relative bg-white px-4 py-5 hover:bg-gray-50 sm:px-6 hover:scale-[102%] transition duration-500 border border-gray-200 rounded-lg shadow-sm hover:shadow-lg"
        >
            <div
                class="relative flex flex-col gap-y-4 md:gap-y-0 md:flex-row justify-between gap-x-6"
            >
                <div class="flex gap-8 flex-col sm:flex-row">
                    <div class="my-auto w-32 flex-shrink-0 flex justify-center">
                        <font-awesome-icon
                            :icon="['fas', 'robot']"
                            class="h-16 w-16 text-cyan-600"
                        />
                    </div>
                    <div class="min-w-0 flex flex-col gap-3 flex-auto">
                        <h3
                            class="text-2xl font-gothic tracking-wide font-semibold leading-8 text-bluewood-950"
                        >
                            <a :href="link">
                                Empower Yourself with AI Job Search Tools
                            </a>
                        </h3>
                        <p class="text-sm leading-6 text-gray-500">
                            Get hired faster with our AI-powered tools. Let your
                            copilot automatically apply to jobs while you focus
                            on preparing for interviews.
                        </p>
                        <ul
                            class="grid gap-2 lg:grid-cols-2 text-gray-700 text-sm"
                        >
                            <li class="flex items-center">
                                <CheckCircleIcon
                                    class="w-5 h-5 mr-2 text-green-500"
                                />
                                Up to 50 Auto-Applications Daily
                            </li>
                            <li class="flex items-center">
                                <CheckCircleIcon
                                    class="w-5 h-5 mr-2 text-green-500"
                                />
                                AI CV & Cover Letter Builder
                            </li>
                            <li class="flex items-center">
                                <CheckCircleIcon
                                    class="w-5 h-5 mr-2 text-green-500"
                                />
                                AI Interview Roleplay
                            </li>
                            <li class="flex items-center">
                                <CheckCircleIcon
                                    class="w-5 h-5 mr-2 text-green-500"
                                />
                                AI Career Advisors
                            </li>
                        </ul>
                        <div class="flex items-center gap-4 text-sm">
                            <div class="flex items-center gap-2">
                                <span class="font-medium text-gray-700"
                                    >Starting from AED 19.90/week</span
                                >
                                <span
                                    class="px-2 py-0.5 rounded-full bg-cyan-50 text-cyan-700 text-xs font-medium"
                                >
                                    or AED 59.90/month
                                </span>
                            </div>
                            <span class="text-gray-500">
                                No commitment. Cancel Anytime
                            </span>
                        </div>
                    </div>
                </div>
                <div class="relative flex items-center gap-x-4 flex-shrink-0">
                    <a
                        class="rounded-md font-gothic bg-cyan-600 px-3.5 py-2.5 text-lg text-white shadow-sm hover:bg-cyan-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-600"
                        :href="link"
                    >
                        Try JobCopilot Now
                    </a>
                </div>
            </div>
        </div>
    </li>
</template>

<script setup>
import { CheckCircleIcon } from "@heroicons/vue/24/solid";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faRobot } from "@fortawesome/free-solid-svg-icons";

library.add(faRobot);

defineProps({
    link: {
        type: String,
        required: true,
    },
});
</script>
