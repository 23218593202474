<template>
    <TransitionRoot appear :show="props.modelValue" as="template">
        <Dialog as="div" @close="closeModal" class="relative z-10">
            <TransitionChild
                as="template"
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
            >
                <div class="fixed inset-0 bg-black/25" />
            </TransitionChild>

            <div class="fixed inset-0 overflow-y-auto">
                <div
                    class="flex min-h-full items-center justify-center p-4 text-center"
                >
                    <TransitionChild
                        as="template"
                        enter="duration-300 ease-out"
                        enter-from="opacity-0 scale-95"
                        enter-to="opacity-100 scale-100"
                        leave="duration-200 ease-in"
                        leave-from="opacity-100 scale-100"
                        leave-to="opacity-0 scale-95"
                    >
                        <DialogPanel
                            class="w-full space-y-4 max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
                        >
                            <!-- Logo -->
                            <div class="flex justify-between">
                                <Logo :dark="false" />
                                <!-- close button -->
                                <button
                                    type="button"
                                    class="inline-flex justify-center rounded-md border border-transparent bg-white/5 p-1.5 text-gray-400 hover:bg-white/10 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-gray-500"
                                    @click="closeModal"
                                >
                                    <span class="sr-only">Close</span>
                                    <XMarkIcon
                                        class="h-5 w-5"
                                        aria-hidden="true"
                                    />
                                </button>
                            </div>
                            <DialogTitle
                                as="h3"
                                class="text-xl font-gothic font-medium leading-6 text-gray-900"
                            >
                                Subscribe to Job Alerts
                            </DialogTitle>

                            <div class="mt-4" v-show="showJobApplyLink">
                                <Anchor
                                    :href="externalUrl"
                                    class="flex gap-2 items-center justify-center"
                                >
                                    Click here to redirect to the job post
                                    <font-awesome-icon
                                        :icon="['fas', 'arrow-right']"
                                        class="text-white"
                                    />
                                </Anchor>
                            </div>

                            <div
                                v-show="emailForm.wasSuccessful"
                                class="flex items-center gap-x-2"
                            >
                                <CheckCircleIcon
                                    class="w-5 h-5 text-green-500"
                                />
                                <span class="text-sm text-gray-500">
                                    Thank you for subscribing! Redirecting you
                                    to the job post...
                                </span>
                            </div>

                            <div
                                v-if="
                                    !emailForm.wasSuccessful &&
                                    !showJobApplyLink
                                "
                                class="space-y-4"
                            >
                                <div class="mt-2">
                                    <p class="text-sm text-gray-500">
                                        Stay ahead in your career with Dubai's
                                        first platform dedicated to remote and
                                        hybrid job opportunities.
                                    </p>
                                </div>

                                <ul class="mt-4 space-y-2">
                                    <li class="flex gap-x-2">
                                        <CheckCircleIcon
                                            class="w-5 h-5 text-green-500"
                                        />
                                        <span class="text-sm text-gray-500">
                                            Weekly job alerts
                                        </span>
                                    </li>
                                    <li class="flex gap-x-2">
                                        <CheckCircleIcon
                                            class="w-5 h-5 text-green-500"
                                        />
                                        <span class="text-sm text-gray-500">
                                            Exclusive insights
                                        </span>
                                    </li>
                                    <li class="flex gap-x-2">
                                        <CheckCircleIcon
                                            class="w-5 h-5 text-green-500"
                                        />
                                        <span class="text-sm text-gray-500">
                                            No spam, ever
                                        </span>
                                    </li>
                                </ul>

                                <!-- Email form -->
                                <form
                                    v-on:submit.prevent="emailSubmit"
                                    class="mt-6 flex flex-col max-w-md gap-4"
                                >
                                    <label for="email-address" class="sr-only"
                                        >Email address</label
                                    >
                                    <input
                                        v-model="emailForm.email"
                                        id="email-address"
                                        name="email"
                                        type="email"
                                        autocomplete="email"
                                        autofocus
                                        required
                                        class="min-w-0 flex-auto rounded-md border-0 bg-white/5 px-3.5 py-2 text-gray-700 shadow-sm ring-1 ring-inset ring-black/10 focus:ring-2 focus:ring-inset focus:ring-cyan-500 sm:text-sm sm:leading-6"
                                        placeholder="Enter your email"
                                    />
                                    <button
                                        :disabled="emailForm.processing"
                                        type="submit"
                                        class="flex-none rounded-md bg-cyan-500 px-3.5 py-2.5 text-lg font-semibold text-white shadow-sm hover:bg-cyan-400 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-cyan-500"
                                    >
                                        Get Job Alerts Now
                                        <font-awesome-icon
                                            :icon="['fas', 'arrow-right']"
                                            class="text-white"
                                        />
                                    </button>
                                </form>

                                <Avatars />

                                <div>
                                    <span class="text-sm text-gray-500">
                                        Don't prefer email? Subscribe via
                                        WhatsApp or Telegram:
                                    </span>
                                </div>

                                <!-- Subscribe via WhatsApp and Telegram in same line -->
                                <div
                                    class="flex flex-wrap gap-4 justify-center"
                                >
                                    <a
                                        @click="handleSocialMediaClick"
                                        href="https://chat.whatsapp.com/JNbOiWkIJpI6OJaDt0oZS5"
                                        target="_blank"
                                        class="inline-flex gap-2 w-full items-center justify-center rounded-md border border-transparent bg-[#25D366] px-4 py-2 text-sm font-medium text-gray-50 hover:bg-[#00BFA5] focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500 focus-visible:ring-offset-2"
                                    >
                                        <!-- whatsapp icon -->
                                        <font-awesome-icon
                                            :icon="['fa-brands', 'fa-whatsapp']"
                                            class="text-white text-2xl"
                                        />
                                        WhatsApp
                                        <font-awesome-icon
                                            :icon="['fas', 'external-link-alt']"
                                            class="text-white text-xs"
                                        />
                                    </a>
                                    <a
                                        @click="handleSocialMediaClick"
                                        href="https://t.me/RemoteJobsDubai"
                                        target="_blank"
                                        class="inline-flex gap-2 w-full items-center justify-center rounded-md border border-transparent bg-[#0088CC] px-4 py-2 text-sm font-medium text-gray-50 hover:bg-[#00ACEE] focus:outline-none focus-visible:ring-2 focus-visible:ring-cyan-500 focus-visible:ring-offset-2"
                                    >
                                        <!-- telegram icon -->
                                        <font-awesome-icon
                                            :icon="['fa-brands', 'fa-telegram']"
                                            class="text-white text-2xl"
                                        />
                                        Telegram
                                        <font-awesome-icon
                                            :icon="['fas', 'external-link-alt']"
                                            class="text-white text-xs"
                                        />
                                    </a>
                                </div>

                                <div class="mt-4">
                                    <a
                                        class="text-sm text-gray-500 hover:text-gray-700 underline"
                                        :href="externalUrl"
                                    >
                                        Already subscribed? Continue applying
                                        for this job.
                                    </a>
                                </div>
                            </div>
                        </DialogPanel>
                    </TransitionChild>
                </div>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup>
import { ref } from "vue";
import { CheckCircleIcon, XMarkIcon } from "@heroicons/vue/24/outline";
import {
    TransitionRoot,
    TransitionChild,
    Dialog,
    DialogPanel,
    DialogTitle,
} from "@headlessui/vue";
import { useEmailForm } from "@/utils/subscribe.js";
import Button from "@/Components/Guest/Button.vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import { faWhatsapp, faTelegram } from "@fortawesome/free-brands-svg-icons";
import Logo from "@/Components/Guest/Logo.vue";
import Avatars from "@/Components/Guest/Avatars.vue";
import {
    faArrowRight,
    faExternalLinkAlt,
} from "@fortawesome/free-solid-svg-icons";
import Anchor from "@/Components/Guest/Anchor.vue";

const props = defineProps({
    modelValue: Boolean,
    externalUrl: String,
});
const emit = defineEmits(["update:modelValue"]);

library.add(faWhatsapp, faTelegram, faArrowRight, faExternalLinkAlt);

const { emailForm, emailSubmit } = useEmailForm(props.externalUrl);
function closeModal() {
    emit("update:modelValue", false);
}

const showJobApplyLink = ref(false);

function handleSocialMediaClick() {
    showJobApplyLink.value = true;
}
</script>
