<!-- components/Guest/Listings/ListingAdvert.vue -->
<template>
    <component :is="selectedAdvert.component" v-bind="selectedAdvert.props" />
</template>

<script setup>
import { computed, inject } from "vue";
import ReziAdvert from "./Adverts/ReziAdvert.vue";
import JobCopilotAdvert from "./Adverts/JobCopilotAdvert.vue";
import PostJobAdvert from "./Adverts/PostJobAdvert.vue";

const route = inject("route");

// Define all possible adverts
const adverts = [
    {
        id: "rezi",
        component: ReziAdvert,
        props: {
            affiliateLink: route("rezi"),
        },
        weight: 34, // Percentage chance of showing this advert
    },
    {
        id: "jobCopilot",
        component: JobCopilotAdvert,
        props: {
            link: route("jobcopilot"),
        },
        weight: 33,
    },
    {
        id: "postJob",
        component: PostJobAdvert,
        props: {
            link: route("filament.user.resources.listings.create"),
        },
        weight: 33,
    },
];

// Select a random advert based on weights and exclusions
const selectedAdvert = computed(() => {
    // Normalize weights for available adverts
    const totalWeight = adverts.reduce((sum, ad) => sum + ad.weight, 0);
    let random = Math.random() * totalWeight;

    for (const advert of adverts) {
        random -= advert.weight;
        if (random <= 0) {
            return advert;
        }
    }

    return adverts[0]; // Fallback to first available advert
});
</script>
